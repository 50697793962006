import * as React from "react";
import { Border as Button } from "../components/tachyons/buttons";
import { Jumbotron, Subtitle } from "../components/tachyons/text";
import FullScreenCenteredTile from "../components/tachyons/layout/fullScreenCenteredTile";
import { Link } from "gatsby";

const Intro = props => (
  <FullScreenCenteredTile className="min-vh-80 mw8 db center">
    <Jumbotron>Hi there, I build websites and mobile apps.</Jumbotron>
    <Subtitle className="mt3 mb4">
      Freelance Javascript developer based in Hackney, East London
    </Subtitle>
    {/* <Button as="link" to="/work" className="dark-blue" grow border>
      See my work
    </Button>
    <span className="db dib-ns v-mid ph3 dark-blue mb2">or</span> */}
    <Button as={Link} to="/contact" className="ba bw2 dark-blue mb5 grow">
      Get in touch
    </Button>
  </FullScreenCenteredTile>
);

export default Intro;
