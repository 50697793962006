import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

import Work from "../components/work";
import Intro from "../components/intro";

const Divider = () => <div className="bt b--dotted w-90 mw8 db center" />;

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Home" />
      <Intro />
      <Divider />
      <Work work={data.allMarkdownRemark.edges} />
      <Divider />
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query homePageQuery {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "recent-work" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            project: title
            image {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  src
                }
              }
            }
            colours
            client
            about
            order
          }
        }
      }
    }
  }
`;
